import firebase from 'firebase/app';
import 'firebase/firebase-firestore';

const config = {
    apiKey: "AIzaSyASrR1cj7Eq_BmWX82jjaqnYuSYluQcvwk",
    authDomain: "km-cams-flamelink.firebaseapp.com",
    projectId: "km-cams-flamelink",
    storageBucket: "km-cams-flamelink.appspot.com",
    messagingSenderId: "155820011145",
    appId: "1:155820011145:web:f48338f753b4b853552058",
    measurementId: "G-E16LXQXG37"
  };

firebase.initializeApp(config);
//const databaseRef = firebase.database().ref();
//export const productsRef = databaseRef.child("products")
const db = firebase.firestore();
export const productsRef = //db.collection("products");
db.collection("products").get().then((querySnapshot) => {
  querySnapshot.forEach((doc) => {
      console.log(`${doc.id} => ${doc.data()}`);
  });
});
export default firebase;