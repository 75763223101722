import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.scss';
import Contact from './pages/Contact';
import Home from './pages/Home';

const App = () => {
  // Here react-router-dom is used, for more information see: https://reactrouter.com/web/guides/quick-start
  // Firebase react guide: https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial

  return (
    <Router>
      <nav>
        <ul>
          <li>
            <Link to="/">Forside</Link>
          </li>
          <li>
            <Link to="/kontakt-oss">Kontakt oss</Link>
          </li>
        </ul>
      </nav>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/kontakt-oss">
          <Contact />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
