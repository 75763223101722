import { FirebaseContext} from '../components/Firebase'; 

const Home = () => {
    return (
        <FirebaseContext.Consumer>
        {firebase => {
          return <div>I've access to Firebase and render something.</div>;
        }}
      </FirebaseContext.Consumer>
    )
}
export default Home;